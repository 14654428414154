<template>
    <div class="card">
        <div class="card-body p-0">
            <div class="card-px text-center py-20 my-10">
                <h2 class="fs-2x fw-bold mb-10">{{ $t('pages.statistic.student.title') }}</h2>
                <p class="text-gray-500 fs-5 fw-semobold mb-13">
                    {{ $t('pages.statistic.student.filter.description') }}
                </p>
                <div class="m-auto mb-10">
                    <el-select class="w-50 user-select" v-model="filter.data.users" :filterable="user.filterable" clearable remote :remote-method="remoteSearchUser" multiple :loading="user.loading" :placeholder="$t('pages.statistic.student.filter.userSearch')">
                        <el-option v-for="(user, userIndex) in user.list" :key="userIndex" :value="user.id" :label="user.full_name + ' (' + user.email +')'"></el-option>
                    </el-select>
                </div>
                <button :data-kt-indicator="filter.loading ? 'on' : null" class="btn btn-primary er fs-6 px-8 py-4" type="button" @click="onFilter" :disabled="filter.loading">
                    <span v-if="!filter.loading" class="indicator-label">{{ $t('pages.statistic.student.filter.button') }}</span>
                    <span v-if="filter.loading" class="indicator-progress">
                        {{ $t("messages.wait") }}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <StatisticStudent class="mt-10" ref="studentStatistic" :users="filter.data.users" @loading="filter.loading = $event"></StatisticStudent>
</template>

<script>
import StatisticStudent from "@/components/statistic/student";

export default {
    name: "index",
    components: {
        StatisticStudent
    },
    data(){
        return {
            filter: {
                loading: false,
                data: {},
            },
            user: {
                filterable: true,
                loading: false,
                list: []
            },
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t('pages.statistic.student.title'), [this.$t('menu.statistic.title')]);
    },
    methods: {
        onFilter(){
            if(!this.filter.data.users ||!Array.isArray(this.filter.data.users) || !this.filter.data.users.length){
                this.$notify({
                    type: 'warning',
                    title: this.$t("messages.warning"),
                    message: this.$t("pages.statistic.student.warning.requiredUser"),
                })

                return;
            }

            this.$refs.studentStatistic.loadStatistic();
        },
        remoteSearchUser(query) {
            if (query) {
                this.user.loading = true;
                this.axios.get(this.endpoints['user'], {
                    params: {
                        magic: query
                    }
                }).then(response => {
                    let data = response.data.data;
                    this.user.list = data;
                }).finally(() => {
                    this.user.loading = false;
                });
            } else {
                this.user.list = [];
            }
        },
    }
}
</script>

<style>
.user-select  .el-input__inner{
    text-align: center;
}
</style>